import React from 'react';
import {useEffect, useState, createElement} from "react";
import config from "../json/config.json";


function getInnerText(element, count = null, endIndex = null) {
    if (typeof element == "string") {
        if (count < endIndex - element.length || endIndex == null || count == null) {
            return element;
        } else {
            return element.substring(0, endIndex - count);
        }

    } else {
        let t = "";
        for (const childElement of element.props.children) {
            let inner = getInnerText(childElement, count + t.length, endIndex);
            t += inner;

        }

        return t;

    }
}

function getElementWithEndIndex(element, count = null, endIndex = null) {
    if (typeof element == "string") {
        if (count < endIndex - element.length || endIndex == null || count == null) {
            return element;
        } else {
            return element.substring(0, endIndex - count);
        }

    } else {
        let t = "";
        let newProps = {...element.props};
        for (const childElement of element.props.children) {
            let inner = getElementWithEndIndex(childElement, count + t.length, endIndex);
            if (typeof inner == "string") {
                t += inner;
            } else {
                t = <span>{t}{inner}</span>;
            }
        }
        let newElement = createElement(element.type, newProps, t);

        return newElement;

    }
}

function TypingEffect(params) {

    let text = getInnerText(params.element);
    const [typing, setTyping] = useState();
    const [index, setIndex] = useState(0);

    let speed = 50;
    if (params.speed != null) {
        speed = params.speed;
    }
    useEffect(() => {
        if (index < text.length) {
            setTimeout(() => {
                setTyping(getElementWithEndIndex(params.element, 0, index + 1));
                setIndex(index + 1);

            }, speed);
        }
    }, [index]);


    if (config.SHOW_ANIMATIONS) {
        return <div>
            {typing}
        </div>;
    } else {
        return <div>
            {params.element}
        </div>;
    }

}


export default TypingEffect;