import React from "react";
import {Collapse, Nav, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGithub, faLinkedin} from "@fortawesome/fontawesome-free-brands";
import {faEnvelope} from "@fortawesome/free-solid-svg-icons";
import ThemeToggler from "./ThemeToggler";
import config from "../json/config.json";


function PortfolioNavbar(params) {
    const [isOpen, setIsOpen] = React.useState(false);

    return <Navbar expand="md" fixed={"top"} className={"navbar"}>
        <NavbarBrand href="/">
            <img src={process.env.PUBLIC_URL+ "/logo192.png"} alt={"Logo"} width={64} height={64}></img>
        </NavbarBrand>

        <NavbarToggler onClick={() => {
            setIsOpen(!isOpen)
        }}/>
        <Collapse isOpen={isOpen} navbar>
            <Nav className="justify-content-center" navbar>
                <NavItem>
                    <NavLink href="#intro" className={"h3 nav-link"}>About</NavLink>
                </NavItem>
                <NavItem>
                    <NavLink href="#projects" className={"h3 nav-link"}>Projects</NavLink>
                </NavItem>
                <NavItem>
                    <NavLink href="#experience" className={"h3 nav-link"}>Experience</NavLink>
                </NavItem>
                <NavItem>
                    <NavLink href={config.SHOW_RESUME ? "#resume" : require("../Professional Resume.pdf")}
                             target="_blank"
                             className={"h3 nav-link"}>
                        Resume
                    </NavLink>
                </NavItem>
            </Nav>
            <Nav className="justify-content-end ms-auto">
                <NavItem className="float-right">
                    <NavLink href={"https://github.com/DrSmCraft"} target={"_blank"}
                    >
                        <p>
                            <span>
                            <FontAwesomeIcon
                                icon={faGithub}
                                size={"2xl"}
                                className={"contact-logo"}
                            /></span>
                        </p>
                    </NavLink>

                </NavItem>
                <NavItem className="float-right">
                    <NavLink href={"https://www.linkedin.com/in/samuil-orlioglu/"} target={"_blank"}
                    >
                        <FontAwesomeIcon
                            icon={faLinkedin}
                            size={"2xl"}
                            className={"contact-logo"}
                        />
                    </NavLink>
                </NavItem>
                <NavItem className="float-right">
                    <NavLink href={"mailto:sorliog@clemson.edu"} target={"_blank"}
                    >
                        <FontAwesomeIcon
                            icon={faEnvelope}
                            size={"2xl"}
                            className={"contact-logo"}
                        />
                    </NavLink>
                </NavItem>
                <NavItem className="ms-auto justify-content-center align-items-center">
                    <ThemeToggler></ThemeToggler>
                </NavItem>
            </Nav>
        </Collapse>
    </Navbar>
}

export default PortfolioNavbar;