import React from 'react';
import {VerticalTimeline, VerticalTimelineElement} from "react-vertical-timeline-component";
import experience from "../json/experience.json";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBriefcase, faSchool} from "@fortawesome/free-solid-svg-icons";
import {Card, CardBody, CardFooter, CardHeader, ListGroup, ListGroupItem} from "reactstrap";
import SkillTag from "./SkillTag";
import config from "../json/config.json";
import 'react-vertical-timeline-component/style.min.css';


function Timeline() {
    return <VerticalTimeline animate={config.SHOW_ANIMATIONS} lineColor={"#D0D0D0"}>

        {experience.major.map((item, index) => {
            return <VerticalTimelineElement
                date={<p>{item.start} - {item.end}</p>}

                contentStyle={{backgroundColor: "var(--card-header)"}}
                style={{overflow: "hidden"}}
                // contentArrowStyle={{borderRight: '7px solid  rgb(33, 150, 243)'}}
                // iconStyle={{background: 'rgb(33, 150, 243)', color: '#FFFFFF'}}
                iconClassName={"timeline-icon"}
                icon={<FontAwesomeIcon icon={item.icon === "faSchool" ? faSchool : faBriefcase}/>}
                key={index}
            >
                <Card className={"project-card-body"}>
                    <CardHeader className={"project-card-header"}>
                        <h3 className={"header3"}>{item.position}</h3>
                        <h4 className={"header4"}>{item.company} - {item.city}, {item.state}</h4>
                    </CardHeader>
                    <CardBody className={"project-card-body"}>
                        <ListGroup className={"project-card-body"}>
                            {item.responsibilities.map((resp, index) => {
                                return <ListGroupItem className={"project-card-body"} key={index}><p>{resp}</p>
                                </ListGroupItem>
                            })}
                        </ListGroup>

                        {item.link != null && <a href={item.link}>Link</a>}
                    </CardBody>
                    <CardFooter className={"project-card-footer"}>
                        {item.tags.map(tag => {
                            return <SkillTag type="technology" value={tag} key={tag}/>
                        })}
                    </CardFooter>
                </Card>
            </VerticalTimelineElement>;
        })}

    </VerticalTimeline>;

}


export default Timeline;
