import React from 'react';
import {
    Card, CardBody, CardFooter, CardHeader, CardImg, CardLink, CardText, CardTitle, Col, Container, Row
} from "reactstrap";

import SkillTag from "./SkillTag";
import projects from "../json/projects.json";
import config from "../json/config.json";


const colPerRow = 3;
let majorProjects = [];
let minorProjects = [];

for (let i = 0; i <= projects.major.length; i += colPerRow) {
    majorProjects.push(projects.major.slice(i, i + colPerRow));
}
for (let i = 0; i <= projects.minor.length; i += colPerRow) {
    minorProjects.push(projects.minor.slice(i, i + colPerRow));
}


function ProjectCard(params) {
    let className = "";
    if (params.index != null && config.SHOW_ANIMATIONS) {
        if (params.index % colPerRow < colPerRow / 2.0) {
            className = "float-in-left project-card-body"
        } else if (params.index % colPerRow > colPerRow / 2.0) {
            className = "float-in-right project-card-body"
        } else {
            className = "project-card-body"
        }
    }

    let imgHeight = 170;
    let imgWidth = 170;

    if (params.logoWidth != null) {
        imgWidth = params.logoWidth;
    }
    if (params.logoHeight != null) {
        imgHeight = params.logoHeight;
    }

    return <Card className={className}>
        <CardHeader className={"project-card-header"}>
            <CardTitle>
                <h3>{params.title}</h3>
            </CardTitle>


        </CardHeader>
        <CardBody className={"project-card-body"}>

            <CardImg src={params.logo} className={"project-card-image"}
                     style={{
                         height: imgHeight, width: imgWidth
                     }}
            />


            <CardText>
                {params.summary}
            </CardText>
            <CardLink href={params.link}>Link</CardLink>
        </CardBody>


        {params.tags == null ? <></> : <CardFooter className={"project-card-footer"}>
            {params.tags.map(tag => <SkillTag type="technology" value={tag} key={tag}/>)}
        </CardFooter>}
    </Card>
}


function Projects(params) {
    return <Container>
        {majorProjects.map((arr, rindex) => {
            return <Row style={{margin: "10px"}} key={"r" + rindex}>
                {arr.map((item, cindex) => {

                    return <Col key={"c" + cindex}>
                        <ProjectCard title={item.title} logo={item.logo} summary={item.summary}
                                     link={item.link} tags={item.tags} index={cindex} logoWidth={item.width}
                                     logoHeight={item.height}></ProjectCard>
                    </Col>;
                })}
            </Row>
        })}
    </Container>
}

export default Projects;