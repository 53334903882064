import React from 'react';
import {Spinner} from "reactstrap";

function LoadingAnimation(params) {
    return <div className={"loading-overlay"}>
        <Spinner></Spinner>

    </div>
}

export default LoadingAnimation;