import React from 'react';
import tags from '../json/tags.json';
import {Badge} from "reactstrap";

function SkillTag(params) {
    let def = undefined;
    // console.log(params);
    if (params.type == "technology") {
        def = tags.technologies[params.value];
    } else if (params.type == "category") {
        def = tags.categories[params.value];

    }
    if (def === undefined) {
        return <Badge className={"tag"}>{params.value}</Badge>
    }
    let color = def.color;

    let href = def.link;

    return <Badge className={"tag"} color={color} href={href} onClick={params.onclick}>{params.value}</Badge>

}

export default SkillTag;