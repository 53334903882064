import React, {useState} from 'react';
import {CardBody} from "reactstrap";
import config from "../json/config.json";
import {getRandomDelay} from "../common/functions";
import Spacer from "../components/Spacer";
import Timeline from "../components/Timeline";
import Projects from "../components/Projects";
import TypingEffect from "../components/TypingEffect";
import LoadingAnimation from "../components/LoadingAnimation";
import PortfolioNavbar from "../components/Navbar";


function PageContent(params) {

    return <div>

        <PortfolioNavbar></PortfolioNavbar>
        <section id={"intro"} style={{paddingTop: "50px"}}>
            <TypingEffect element={<h1 className={"intro"}>Hello, I'm <span className={"name"}>Samuil Orlioglu</span>
            </h1>} animate={config.SHOW_ANIMATIONS}></TypingEffect>
        </section>

        <section id="about">
            <CardBody className={"about"}>
                <TypingEffect
                    element={<p className={"p"}>I am a software developer, based in Upstate South Carolina.
                        I have application development experience with Java, Spring Boot, .NET, C#, Python and C++. My
                        interests include desktop application development, web development, low-level security and 3D
                        graphics.</p>} speed={5}
                    animate={config.SHOW_ANIMATIONS}>
                </TypingEffect>
            </CardBody>
        </section>

        <Spacer></Spacer>

        <section>
            <section id={"projects"}>
                <h2 className={"header2 section-header"}>Projects</h2>
                <Projects></Projects>
            </section>

            <Spacer></Spacer>
            <section id={"experience"}>
                <h2 className={"header2 section-header"}>Experience</h2>
                <Timeline></Timeline>
            </section>
        </section>

        <Spacer></Spacer>


        {config.SHOW_RESUME ?
            <section id={"resume"}>
                <h2 className={"header2 section-header"}>Resume</h2>
                <iframe src={"https://drsmcraft.github.io/virtual-resume/"} width={1200} height={2500}></iframe>
            </section> :
            <></>
        }

    </div>
}

function SinglePage(params) {
    let [isLoaded, setLoaded] = useState(!config.SHOW_LOAD_ANIMATION);

    let delay = getRandomDelay(config.MIN_LOAD_DELAY, config.MAX_LOAD_DELAY)
    if (config.SHOW_LOAD_ANIMATION) {
        setTimeout(() => {
            setLoaded(true);
        }, delay);
    }

    if (config.SHOW_PARALLAX) {
        return <div className={"background parallax"}>
            {isLoaded ? <PageContent></PageContent> : <LoadingAnimation></LoadingAnimation>}
        </div>
    } else {
        return <div className={"background"}>
            {isLoaded ? <PageContent></PageContent> : <LoadingAnimation></LoadingAnimation>}
        </div>
    }
}

export default SinglePage;
