import React, {useState} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

import {BrowserRouter, Routes, Route} from "react-router-dom";
import SinglePage from "./pages/SinglePage";
import config from "./json/config.json";
import eventManager from "./events/Events";
import './App.css';


function App() {

    let [theme, setTheme] = useState(config.DEFAULT_THEME);

    eventManager.subscribe("themeChanged", (data => {
        setTheme(data["newTheme"]);
    }));

    return (<div className={theme == "light" ? "App light-theme" : "App dark-theme"}>
        <BrowserRouter>
            <Routes>
                <Route path="/">
                    <Route index element={<SinglePage></SinglePage>}/>
                </Route>
            </Routes>
        </BrowserRouter>
    </div>);
}

export default App;
