import React, {useState} from "react";
import config from "../json/config.json";
import {Button} from "reactstrap";
import eventManager from "../events/Events";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMoon, faSun} from "@fortawesome/free-solid-svg-icons";


function ThemeToggler(params) {
    let [theme, setTheme] = useState(config.DEFAULT_THEME);


    return <div style={{padding: "12px"}}>
        <Button outline={false} size={"sm"}
                onClick={(evt) => {
                    let newTheme = 'light';
                    if (theme === 'light') {
                        newTheme = 'dark';
                        setTheme(newTheme);
                    } else {
                        newTheme = 'light';
                        setTheme(newTheme);
                    }
                    eventManager.dispatch("themeChanged", {"newTheme": newTheme});
                    localStorage.setItem("theme", newTheme);
                    return true;
                }}  className={"theme-toggler"}>


            <FontAwesomeIcon icon={theme === 'light' ? faMoon : faSun}
                             size={"xl"} color={theme === 'light' ? "white" : "black"}
            >

            </FontAwesomeIcon>
        </Button>
    </div>;
}

export default ThemeToggler;